import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Booking from "../api/booking";
import "../App.scss";
// !------
import AgGridLocale from "../components/AgGrid/AG_GRID_LOCALE_FR.json";
import { DateRenderer } from "../components/AgGrid/CellRenderer";
import ContractDetails from "../components/ContractDetails";
import LokaSnackBar from "../components/LokaSnackBar";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import SnackBarContext from "../Contexts/SnackBarContext";
import UserContext from "../Contexts/UserContext";
import { Tooltip } from "@material-ui/core";
import LokaActivityIndicator from "../components/LokaActivityIndicator";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";

LicenseManager.setLicenseKey(`${process.env.REACT_APP_AG_GRID_LICENCE_KEY}`);

const BookingsView = ({ members }) => {
  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const { user } = useContext(UserContext);

  const { setSnackBar } = useContext(SnackBarContext);

  const { GetApiCompanyDiscountGrid, GetGridBookings, Update } = Booking || {};
  const [contractDetails, setContractDetails] = useState(null);
  const [localProps, setLocalProps] = useState({
    isGridReady: false,
  });
  const [view, setView] = useState("standard");

  const gridRef = useRef(null);

  const constructor = async () => {
    setActivityIndicator({
      isOpen: true,
      message: Language.appBar.loading,
    });
    const { data } = await GetGridBookings({
      token: user?.token,
      language: "fr",
    });
    const companyDiscountGrid = await GetApiCompanyDiscountGrid({
      idCompany: user.idCompany,
      token: user.token,
    });
    const bookingsList = data?.response || [];
    const defaultViews = [
      "standard",
      "planning",
      "counter",
      "bookingType",
      "accounting",
      "export",
    ];
    setLocalProps({
      companyDiscountGrid,
      defaultViews,
      rowData: bookingsList,
    });
    setActivityIndicator({
      isOpen: false,
      message: null,
    });
  };
  useEffect(() => {
    constructor();
  }, []);

  const getMember = (memberId) => {
    const _member = members?.find(({ id }) => id === memberId[0]);
    return _member ? `${_member?.firstName} ${_member?.lastName}` : ``;
  };

  const gridApiHandler = (apiType) => {
    const { api } = gridRef.current || {};
    if (api) {
      switch (apiType) {
        case "exportDataAsExcel":
          api.exportDataAsExcel();
          break;
        default:
          console.log("This grid api type is not used in LOKA app.");
      }
    }
  };

  const handleCloseAndRefresh = async ({ snackbar }) => {
    const { isOpen, message, status } = snackbar || {};
    constructor();
    setContractDetails(null);
    setSnackBar({
      isOpen: isOpen,
      message: message,
      status: status,
    });
  };
  const handleMergeBooking = ({ booking }) => {
    setContractDetails(booking);
    constructor();
  };

  const onFirstDataRendered = (params) => {
    /**Display data on full width */
    params.api.sizeColumnsToFit();
  };

  const defaultColDef = {
    resizable: true,
    rowSelection: "multiple",
    filter: "agSetColumnFilter",
    floatingFilter: true,
    floatingFiltersHeight: 10,
    sortable: true,
    suppressMenu: true,
  };

  const columnDefs = {
    standard: [
      // {
      //   field: "shortId",
      //   headerName: "Id",
      //   type: "textColumn",
      //   maxWidth: 60,
      // },
      {
        field: "contractType",
        headerName: "Type contrat",
        type: "textColumn",
        valueGetter: ({ data }) =>
          localProps?.companyDiscountGrid?.find(
            ({ key }) => key === data.contractType
          )?.label || "LOCATION",
      },
      {
        field: "internId",
        headerName: "N° parc",
        type: "textColumn",
        width: 120,
      },
      {
        field: "createdAt",
        headerName: "Date de création",
        type: "dateColumn",
        width: 120,
      },
      {
        field: "createdBy",
        headerName: "Loker",
        type: "textColumn",
        valueGetter: ({ data }) => getMember([data.createdBy]),
        width: 120,
      },
      {
        field: "checker",
        headerName: "Checker",
        type: "textColumn",
        valueGetter: ({ data }) => getMember([data?.checker]),
        width: 120,
      },
      {
        field: "startDay",
        headerName: "Départ",
        type: "dateColumn",
        width: 120,
      },
      {
        field: "stopDay",
        headerName: "Retour",
        type: "dateColumn",
      },
      {
        field: "customer",
        headerName: "Client",
        type: "textColumn",
      },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
        width: 120,
      },
      {
        field: "brand",
        headerName: "Marque",
        type: "textColumn",
        width: 120,
      },
      {
        field: "model",
        headerName: "Modèle",
        type: "textColumn",
        width: 120,
      },
      {
        field: "departureAgency",
        headerName: "Agence départ",
        type: "textColumn",
        width: 120,
      },
      {
        field: "bookingStatus",
        headerName: "Statut",
        type: "textColumn",
        valueGetter: ({ data }) =>
          Language.bookingStatusRenderer?.[data.status],
        width: 120,
      },
      {
        field: "comment",
        headerName: "Commentaire",
        type: "textColumn",
        width: 350,
      },
    ],
    planning: [
      { field: "shortId", headerName: "Id", type: "textColumn", maxWidth: 60 },
      {
        field: "createdBy",
        headerName: "Loker",
        type: "textColumn",
        valueGetter: ({ data }) => getMember([data.createdBy]),
        width: 120,
      },
      { field: "customer", headerName: "Client", type: "textColumn" },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque", type: "textColumn" },
      { field: "model", headerName: "Modèle", type: "textColumn" },
      {
        field: "createdAt",
        headerName: "Date de création",
        type: "dateColumn",
      },
      {
        field: "startDay",
        headerName: "Départ",
        type: "dateColumn",
      },
      {
        field: "stopDay",
        headerName: "Retour",
        type: "dateColumn",
      },
    ],
    counter: [
      { field: "shortId", headerName: "Id", type: "textColumn", maxWidth: 60 },
      {
        field: "createdAt",
        headerName: "Date de création",
        type: "dateColumn",
      },
      {
        field: "startDay",
        headerName: "Départ",
        type: "dateColumn",
      },
      { field: "customer", headerName: "Client", type: "textColumn" },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque", type: "textColumn" },
      { field: "model", headerName: "Modèle", type: "textColumn" },
      {
        field: "contractType",
        headerName: "Type contrat",
        type: "textColumn",
        valueGetter: ({ data }) =>
          localProps?.companyDiscountGrid?.find(
            ({ key }) => key === data.contractType
          )?.label || "LOCATION",
      },
      {
        field: "realCounter",
        headerName: "Compteur (heures/bottes)",
        type: "textColumn",
      },
    ],
    bookingType: [
      { field: "shortId", headerName: "Id", type: "textColumn", maxWidth: 60 },
      {
        field: "internId",
        headerName: "N° parc",
        type: "textColumn",
        width: 120,
      },
      {
        field: "createdAt",
        headerName: "Date de création",
        type: "dateColumn",
      },
      {
        field: "startDay",
        headerName: "Départ",
        type: "dateColumn",
      },
      {
        field: "stopDay",
        headerName: "Retour",
        type: "dateColumn",
      },
      { field: "customer", headerName: "Client", type: "textColumn" },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
      },
      { field: "brand", headerName: "Marque", type: "textColumn" },
      { field: "model", headerName: "Modèle", type: "textColumn" },
      {
        field: "discount",
        headerName: "Remise",
        type: "textColumn",
      },
      {
        field: "contractType",
        headerName: "Type contrat",
        type: "textColumn",
        valueGetter: ({ data }) =>
          localProps?.companyDiscountGrid?.find(
            ({ key }) => key === data.contractType
          )?.label || "LOCATION",
      },
      {
        field: "realCounter",
        headerName: "Compteur (heures/bottes)",
        type: "textColumn",
      },
    ],
    accounting: [
      {
        field: "shortId",
        headerName: "Id",
        type: "textColumn",
        maxWidth: 60,
      },
      {
        field: "createdAt",
        headerName: "Date de création",
        type: "dateColumn",
        width: 120,
      },
      {
        field: "createdBy",
        headerName: "Loker",
        type: "textColumn",
        valueGetter: ({ data }) => getMember([data.createdBy]),
        width: 120,
      },
      {
        field: "startDay",
        headerName: "Départ",
        type: "dateColumn",
        width: 120,
      },
      {
        field: "stopDay",
        headerName: "Retour",
        type: "dateColumn",
      },
      {
        field: "customer",
        headerName: "Client",
        type: "textColumn",
      },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
        width: 120,
      },
      {
        field: "brand",
        headerName: "Marque",
        type: "textColumn",
        width: 120,
      },
      {
        field: "model",
        headerName: "Modèle",
        type: "textColumn",
        width: 120,
      },
      {
        field: "internId",
        headerName: "N° parc",
        type: "textColumn",
        width: 120,
      },
      {
        field: "comment",
        headerName: "Commentaire",
        type: "textColumn",
        width: 350,
      },
      {
        field: "bookingStatus",
        headerName: "Statut",
        type: "textColumn",
        valueGetter: ({ data }) =>
          Language.bookingStatusRenderer?.[data.status],
        width: 120,
      },
      {
        field: "contractType",
        headerName: "Type contrat",
        type: "textColumn",
        valueGetter: ({ data }) =>
          localProps?.companyDiscountGrid?.find(
            ({ key }) => key === data.contractType
          )?.label || "LOCATION",
      },
      {
        field: "realCounter",
        headerName: "Compteur (heures/bottes)",
        type: "textColumn",
      },
      {
        editable: true,
        headerName: "N° facture",
        type: "textColumn",
        width: 120,
        valueGetter: ({ data }) => data.billId,
        valueSetter: ({ data, newValue, oldValue }) => {
          const isBookingBillable =
            newValue !== oldValue &&
            ["invoiced", "closed"].includes(data.status);
          if (isBookingBillable) {
            updateBillId({
              booking: data?.jsonObject,
              newBillId: newValue,
              oldBillId: oldValue,
            });

            data.billId = newValue;
            data.status = newValue?.length ? "invoiced" : "closed";
          } else {
            setSnackBar({
              isOpen: true,
              message: Language.addBooking.thisBookingIsNotBillable,
              status: "error",
            });
          }

          return isBookingBillable ? true : false;
        },
      },
    ],
    export: [
      {
        field: "shortId",
        headerName: "Id",
        type: "textColumn",
        maxWidth: 60,
      },
      {
        field: "internId",
        headerName: "N° parc",
        type: "textColumn",
        width: 120,
      },
      {
        field: "createdAt",
        headerName: "Date de création",
        type: "dateColumn",
        width: 120,
      },
      {
        field: "createdBy",
        headerName: "Loker",
        type: "textColumn",
        valueGetter: ({ data }) => getMember([data.createdBy]),
        width: 120,
      },
      {
        field: "startDay",
        headerName: "Départ",
        type: "dateColumn",
        width: 120,
      },
      {
        field: "stopDay",
        headerName: "Retour",
        type: "dateColumn",
      },
      {
        field: "customer",
        headerName: "Client",
        type: "textColumn",
      },
      {
        headerName: "Nature",
        type: "textColumn",
        valueGetter: ({ data }) => Language.categoryConverter?.[data.category],
        width: 120,
      },
      {
        field: "brand",
        headerName: "Marque",
        type: "textColumn",
        width: 120,
      },
      {
        field: "model",
        headerName: "Modèle",
        type: "textColumn",
        width: 120,
      },
      {
        field: "departureAgency",
        headerName: "Agence départ",
        type: "textColumn",
        width: 120,
      },
      {
        field: "bookingStatus",
        headerName: "Statut",
        type: "textColumn",
        valueGetter: ({ data }) =>
          Language.bookingStatusRenderer?.[data.status],
        width: 120,
      },
      {
        field: "comment",
        headerName: "Commentaire",
        type: "textColumn",
        width: 350,
      },
      {
        field: "discount",
        headerName: "Remise",
        type: "textColumn",
      },
      {
        field: "contractType",
        headerName: "Type contrat",
        type: "textColumn",
        valueGetter: ({ data }) =>
          localProps?.companyDiscountGrid?.find(
            ({ key }) => key === data.contractType
          )?.label || "LOCATION",
      },
      {
        field: "realCounter",
        headerName: "Compteur (heures/bottes)",
        type: "textColumn",
      },
      {
        editable: true,
        headerName: "N° facture",
        type: "textColumn",
        width: 120,
        valueGetter: ({ data }) => data.billId,
        valueSetter: ({ data, newValue, oldValue }) => {
          const isBookingBillable =
            newValue !== oldValue &&
            ["invoiced", "closed"].includes(data.status);
          if (isBookingBillable) {
            updateBillId({
              booking: data?.jsonObject,
              newBillId: newValue,
              oldBillId: oldValue,
            });

            data.billId = newValue;
            data.status = newValue?.length ? "invoiced" : "closed";
          } else {
            setSnackBar({
              isOpen: true,
              message: Language.addBooking.thisBookingIsNotBillable,
              status: "error",
            });
          }

          return isBookingBillable ? true : false;
        },
      },
    ],
  };

  const columnTypes = {
    dateColumn: {
      cellRenderer: "DateRenderer",
      filter: "agDateColumnFilter",
      comparator: (valueA, valueB) => moment(valueA) - moment(valueB),
      suppressMenu: true,
    },
    numericColumn: {
      filter: "agNumberColumnFilter",
      comparator: (valueA, valueB) => moment(valueA) - moment(valueB),
      suppressMenu: true,
    },
    textColumn: {
      comparator: (valueA, valueB) => {
        if (valueA === valueB) return 0;
        return valueA > valueB ? 1 : -1;
      },
    },
  };
  const rowClassRules = {
    "status-closed": function (params) {
      return params.data.status === "closed";
    },

    "status-invoiced": function (params) {
      return params.data.status === "invoiced";
    },
  };

  const updateBillId = async ({ booking, newBillId, oldBillId }) => {
    /**Updating billId is the only way to set a stage to "invoiced"
     * @mikl
     */
    const _stage = newBillId?.length ? "invoiced" : "closed";
    const _booking = { ...booking, billId: newBillId, stage: _stage };
    const { status } =
      newBillId !== oldBillId
        ? await Update({ booking: _booking, token: user?.token })
        : "no update to send";
    status === 200 &&
      setSnackBar({
        isOpen: true,
        message: Language.addBooking.updateBookingSuccess,
        status: "success",
      });
  };

  const { defaultViews, rowData = [] } = localProps || {};

  return (
    <div className="ag-theme-alpine" style={{ height: "100%", margin: "2%" }}>
      <Box
        key="viewTitle"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "2%",
          marginBottom: "2%",
          borderBottomColor: Colors.greyHighShaded,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="view-select-label">Vues</InputLabel>
            <Select
              labelId="view-select-label"
              id="view-select"
              value={view}
              label="Vue"
              onChange={({ target }) => setView(target.value)}
            >
              {defaultViews?.map((view) => (
                <MenuItem key={view} value={view}>
                  {Language.viewLabels[view]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Tooltip title={Language.calendar.refreshData}>
            <RefreshIcon
              style={{ color: Colors.green, cursor: "pointer", marginLeft: 5 }}
              onClick={() => constructor()}
            />
          </Tooltip>
          <DownloadIcon
            style={{ color: Colors.green, cursor: "pointer", marginLeft: 5 }}
            onClick={() => gridApiHandler("exportDataAsExcel")}
          />
        </Box>
      </Box>
      {rowData?.length && (
        <div className="ag-theme-grid">
          <AgGridReact
            columnTypes={columnTypes}
            localeText={AgGridLocale}
            rowData={rowData}
            rowClassRules={rowClassRules}
            domLayout="autoHeight"
            ref={gridRef}
            defaultColDef={defaultColDef}
            rowStyle={{
              borderBottomWidth: 1,
              borderColor: Colors.greyShaded,
              textAlign: "center",
              fontSize: "12px",
            }}
            rowHeight={50}
            onCellClicked={({ data: { jsonObject }, colDef: { headerName } }) =>
              headerName !== "N° facture" && setContractDetails(jsonObject)
            }
            paginationPageSize={100}
            pagination={true}
            suppressCellSelection={true}
            onFirstDataRendered={onFirstDataRendered}
            columnDefs={columnDefs?.[view]}
            frameworkComponents={{
              DateRenderer: DateRenderer,
            }}
          />
        </div>
      )}
      {contractDetails !== null && (
        <ContractDetails
          contractDetails={contractDetails}
          discountGrid={localProps.companyDiscountGrid}
          handleMergeBooking={(props) => handleMergeBooking(props)}
          members={members}
          setContractDetails={setContractDetails}
          handleCloseAndRefresh={(props) => handleCloseAndRefresh(props)}
        />
      )}
      <LokaSnackBar />
      <LokaActivityIndicator />
    </div>
  );
};

export default BookingsView;
