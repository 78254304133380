import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Booking from "../api/booking";
import Machine from "../api/machine";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import Styles from "../constants/styles.json";
import ActivityIndicatorContext from "../Contexts/ActivityIndicatorContext";
import CompanyContext from "../Contexts/CompanyContext";
import UserContext from "../Contexts/UserContext";
import DirectionMapView from "./DirectionMapView";
import LokaActivityIndicator from "./LokaActivityIndicator";
import LokaDatePicker from "./LokaDatePicker";
import MachineApi from "../api/machine";
import CategoryApi from "../api/category";
import { integerPropType } from "@mui/utils";

const AddUpdateMachine = ({
  handleClose,
  handleMergeBooking,
  initialMachine,
  members,
  role,
  setSnackBar,
}) => {
  const { setActivityIndicator } = useContext(ActivityIndicatorContext);
  const { user } = useContext(UserContext);
  const { company } = useContext(CompanyContext);

  const [machine, setMachine] = useState(initialMachine);
  const [localProps, setlocalProps] = useState({ loading: true });

  const constructor = async () => {
    const companyCategories = await CategoryApi.GetByCompany({
      defaultCategories: company?.defaultCategories,
      token: user?.token,
    });

    setMachine({ machine: { category: companyCategories?.[0]?.key } });
    setlocalProps({ companyCategories, loading: false });
  };

  const handleInputChange = ({ stepKey, inpKey, value }) => {
    const machineStepValue = machine?.[stepKey] || {};
    setMachine({
      ...machine,
      [stepKey]: { ...machineStepValue, [inpKey]: value },
    });
  };

  const handleSelectCategory = async ({ category }) => {
    /**add a confirmation modal here because user will loose its data */
    const machineConfiguration = await MachineApi.Config({
      category,
      token: user?.token,
    });
    setMachine({ machine: { category } });
    setlocalProps({ ...localProps, machineConfiguration });
  };

  useEffect(() => constructor(), []);

  const { companyCategories, loading, machineConfiguration } = localProps || {};
  console.log({ machineConfiguration });

  const steps = [
    {
      helpers: "1. Identification",
      key: "machine",
      type: "standard",
    },
    {
      helpers: "2. Caractéristiques",
      key: "machineFeatures",
      type: "standard",
    },
    {
      helpers: "3. Options",
      key: "options",
      type: "standard",
    },
    {
      helpers: "4. Tarifs",
      key: "rentMode",
      type: "standard",
    },
    {
      helpers: "5. Prestations",
      key: "benefits",
      type: "standard",
    },
    {
      helpers: "6. Accessoires",
      key: "equipments",
      type: "standard",
    },
    {
      helpers: "7. Photos",
      key: "pictures",
      type: "standard",
    },
    {
      helpers: "8. Transport",
      key: "delivery",
      type: "standard",
    },
    {
      helpers: "9. Disponibilité",
      key: "availableRangeDates",
      type: "standard",
    },
    {
      helpers: "10. Groupes d'appartenance",
      key: "group",
      type: "standard",
    },
  ];

  return loading ? null : (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={true}
      onClose={() => handleClose()}
    >
      <Box sx={{ backgroundColor: Colors.grey }}>
        <Toolbar>
          <IconButton
            edge="start"
            onClick={() => handleClose()}
            aria-label="close"
          >
            <CloseIcon sx={{ color: Colors.white }} />
          </IconButton>
          <Typography variant="h6" color={Colors.white}>
            {role === "addMachine"
              ? Language.addMachine.createMachine
              : Language.addMachine.updateMachine}
          </Typography>
        </Toolbar>
      </Box>
      <DialogContent>
        {role === "addMachine" && (
          <div>
            <Select
              size="small"
              value={machine.machine.category}
              onChange={({ target }) =>
                handleSelectCategory({ category: target?.value })
              }
              sx={{ width: "100%" }}
            >
              <MenuItem disabled key="placeholder" value="placeholder">
                {Language.addMachine.selectCategory}
              </MenuItem>
              {companyCategories?.map(({ name, key: categoryKey }) => (
                <MenuItem key={categoryKey} value={categoryKey}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        {machineConfiguration
          ? steps.map(({ helpers, key: stepKey }) => {
              const inputList = machineConfiguration?.[stepKey]?.items;
              return (
                <Box key={stepKey} flex={2} padding={5}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    sx={{
                      borderBottomWidth: 1,
                      borderBottomColor: Colors.greyShaded,
                      borderBottomStyle: "solid",
                    }}
                    paddingBottom={2}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Typography variant="h6" color={Colors.green}>
                        {`${helpers}`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    {inputList?.map(
                      ({
                        label: inpLabel,
                        placeholder: inpPlaceholder,
                        name: inpName,
                        type: inpType,
                        key: inpKey,
                      }) => {
                        return inpType === "default" ? (
                          <TextField
                            key={inpKey}
                            size="small"
                            id={inpKey}
                            label={inpLabel}
                            value={machine?.[stepKey]?.[inpKey]}
                            onChange={({ target }) =>
                              handleInputChange({
                                inpKey,
                                stepKey,
                                value: target?.value,
                              })
                            }
                          />
                        ) : null;
                      }
                    )}
                  </Box>
                </Box>
              );
            })
          : null}
      </DialogContent>
    </Dialog>
  );
};
export default AddUpdateMachine;
