import AddBoxIcon from "@mui/icons-material/AddBox";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageIcon from "@mui/icons-material/Image";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MailIcon from "@mui/icons-material/Mail";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Link,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import Booking from "../api/booking";
import ApiMachine from "../api/machine";
import Colors from "../constants/colors.json";
import Language from "../constants/language_fr.json";
import SnackBarContext from "../Contexts/SnackBarContext";
import UserContext from "../Contexts/UserContext";
import AddUpdateBooking from "./AddUpdateBooking";
import LokaDatePicker from "./LokaDatePicker";
import LokaSnackBar from "./LokaSnackBar";
import ActionModalContext from "../Contexts/ActionModalContext";
import Axios from "axios";

const ContractDetails = (props) => {
  const { setActionModal } = useContext(ActionModalContext);
  const { setSnackBar } = useContext(SnackBarContext);
  const { user } = useContext(UserContext);
  const { token } = user || {};
  const {
    deleteBooking: deleteBookingRestriction,
    updateBooking: updateBookingRestriction,
  } = user?.configuration?.restrictions || {};

  const {
    contractDetails,
    handleCloseAndRefresh,
    handleMergeBooking,
    members,
    setContractDetails,
  } = props || {};
  const { id: idBooking } = contractDetails || {};

  const { DeleteById, SendMeBookingByMail } = Booking || {};

  const [activeStep, setActiveStep] = useState(0);
  const [isOpenAddUpBook, setIsOpenAddUpBook] = useState(false);
  const [localProps, setLocalProps] = useState({ loading: true });
  const [tab, setTab] = useState(0);

  const constructor = async () => {
    const { ConfigBooking, ConfigBookingKeyLabelConverter } = Booking;
    const {
      Config,
      ConfigKeyLabelConverter,
      ConfigKeyUnityConverter,
      getById,
    } = ApiMachine;

    const { category } = contractDetails?.machine?.machine || {};
    const _keyUnityConverter = await ConfigKeyUnityConverter({
      category,
      token,
    });
    const _bookingConfig = await ConfigBooking({
      category: category,
      token: token,
    });
    const _bookingConfigKeyLabelConverter =
      await ConfigBookingKeyLabelConverter({
        category: category,
        token: token,
      });
    const _machineConfigReq = await Config({
      category: category,
      token: token,
    });
    const _configKeyLabelConverter = await ConfigKeyLabelConverter({
      category: category,
      token: token,
    });
    const _keyLabelConverter = {
      ..._configKeyLabelConverter,
      ..._bookingConfigKeyLabelConverter,
    };
    const { id: idMachine } = contractDetails.machine;
    const _machine = await getById({ idMachine, token });

    setLocalProps({
      bookingConfiguration: _bookingConfig,
      keyLabelConverter: _keyLabelConverter,
      keyUnityConverter: _keyUnityConverter,
      loading: false,
      machineConfiguration: _machineConfigReq,
      machine: _machine,
    });
  };

  const {
    bookingConfiguration,
    keyLabelConverter,
    keyUnityConverter,
    loading,
    machineConfiguration,
  } = localProps || {};
  useEffect(() => {
    constructor();
  }, []);
  const {
    contractsToStartCheckings,
    contractsToStopCheckings,
    createdAt,
    createdBy,
    customer,
    delivery,
    equipmentsSelected,
    rentalPeriod,
    rentalPrices,
    servicesSelected,
  } = contractDetails || {};
  const { customer: customerConfiguration } = bookingConfiguration || {};
  const { checkings: checkingsConfig, machine: machineConfig } =
    machineConfiguration || {};
  const { machine, machineFeatures, options, rentMode, pictures } =
    localProps?.machine || {};

  const appBarButtonList = [
    {
      key: "deleteBookingById",
      icon: <DeleteIcon />,
      onClick: () => handleDeleteBooking(),
      restriction: deleteBookingRestriction,
      tooltip: Language.contractDetails.deleteBooking,
    },
    {
      key: "updateBooking",
      icon: <ModeEditIcon />,
      onClick: () => setIsOpenAddUpBook(true),
      restriction: updateBookingRestriction,
      tooltip: Language.contractDetails.updateBooking,
    },
    {
      key: "sendMeBookingByMail",
      icon: <MailIcon />,
      onClick: () =>
        SendMeBookingByMail({
          idBooking,
          token,
        }),
      tooltip: Language.contractDetails.sendMeBookingByMail,
    },
    {
      key: "pdfBookingById",
      icon: <PictureAsPdfIcon />,
      onClick: () => handleGetPdfBooking({ docType: "contract" }),
      restriction: false,
      tooltip: Language.contractDetails.pdfBooking,
    },
  ];

  const checkingsButtonList = [
    {
      icon: <AddBoxIcon sx={{ color: Colors.green, fontSize: 30 }} />,
      key: "contractsToStartCheckings",
      onClick: () =>
        setSnackBar({
          isOpen: true,
          message:
            "👷🏻‍♂️ On a encore un peu de travail ici, mais tu peux gérer tes états des lieux dans l'application mobile.",
          status: "success",
        }),
      title: Language.contractDetails.addContractToStartChecking,
    },
    {
      icon: <AddBoxIcon sx={{ color: Colors.green, fontSize: 30 }} />,
      key: "contractsToStopCheckings",
      onClick: () =>
        setSnackBar({
          isOpen: true,
          message:
            "👷🏻‍♂️ On a encore un peu de travail ici, mais tu peux gérer tes états des lieux dans l'application mobile.",
          status: "success",
        }),
      title: Language.contractDetails.addContractToStopChecking,
    },
  ];

  const displayImage = ({ key, notes, url }) => {
    const isAssigned = localProps?.machine?.pictures?.[key] === url;
    return (
      <Box key={key}>
        {["frontLeft", "frontRight", "rearLeft", "rearRight"].includes(key) ? (
          <Tooltip
            title={
              isAssigned
                ? Language.contractDetails.imageIsAssigned
                : Language.contractDetails.assignImage
            }
          >
            <Box
              style={{
                position: "absolute",
                padding: 10,
                borderRadius: 50,
                backgroundColor: isAssigned ? Colors.greyShaded : Colors.green,
                opacity: 0.8,
                cursor: !isAssigned ? "pointer" : "standard",
                display: "flex",
              }}
            >
              <AddToPhotosIcon
                style={{ color: Colors.white }}
                onClick={() =>
                  !isAssigned ? handleUpdateMachinePicture({ key, url }) : null
                }
              />
            </Box>
          </Tooltip>
        ) : null}
        {notes ? (
          <Box>
            <Typography
              style={{
                borderBottomStyle: "solid",
                color: Colors.grayText,
                borderWidth: 1,
                borderColor: Colors.greyHighShaded,
                fontStyle: "italic",
                textAlign: "right",
              }}
            >
              {notes}
            </Typography>
          </Box>
        ) : null}
        {url ? (
          <img src={url} height={500} />
        ) : (
          <Box
            style={{
              height: 500,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ImageIcon size={30} />
          </Box>
        )}
      </Box>
    );
  };

  const handleUpdateMachinePicture = async ({ key, url }) => {
    const { updateById } = ApiMachine;
    const { machine } = localProps;
    const pictures = { ...machine.pictures, [key]: url };
    const body = { ...machine, pictures };

    const { status } = await updateById({ body, token });

    if (status === 200) {
      setSnackBar({
        isOpen: true,
        message: "👏 L'image est désormais affectée.",
        status: "success",
      });
      constructor();
    } else {
      setSnackBar({
        isOpen: true,
        message: "😔 Quelquechose s'est mal passé lors de la mise à jour.",
        status: "error",
      });
    }
  };

  const EquipmentsItem = ({ data }) => {
    const _data = data ? Object.keys(data).filter((el) => data[el]) : [];

    return (
      <>
        {_data?.length ? (
          _data.map(
            (key) =>
              data[key] && (
                <Typography color={Colors.grey} key={key}>
                  {`${keyLabelConverter[key]} : ${rentalPrices.quantity} ${
                    Language.contractDetails[rentalPrices.key]
                  } x ${data[key][rentalPrices.key]}€`}
                </Typography>
              )
          )
        ) : (
          <Typography color={Colors.grey}>
            {Language.contractDetails.noEquipmentSelected}
          </Typography>
        )}
      </>
    );
  };

  const handleDeleteBooking = async () => {
    const deleteBooking = async () => {
      const status = await DeleteById({ idBooking, token });
      if (status === 200) {
        handleCloseAndRefresh({
          snackbar: {
            isOpen: true,
            message: "👏 Contrat supprimé.",
            status: "success",
          },
        });
      } else {
        setSnackBar({
          isOpen: true,
          message: "😲 Echec suppression.",
          status: "error",
        });
      }
    };
    setActionModal({
      handleValid: () => deleteBooking(),
      isOpen: true,
      message: Language.actionModal.bookingDefinitlyDeleted,
      status: null,
      title: Language.actionModal.caution,
    });
  };

  const handleGetPdfBooking = async ({ docType = "contract" }) => {
    const {
      data: { base64Pdf },
      status,
    } = await Booking?.GetPdfBooking({
      docType,
      idBooking,
      token,
    });
    const downloadLink = document.createElement("a");
    const fileName = `${idBooking}.pdf`;
    downloadLink.href = base64Pdf;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const handleGetCheckingImages = async ({ checking, checkingKey }) => {
    checkingsConfig?.items
      .filter(
        ({ key, type }) =>
          type === "picture" &&
          (checking?.[key]?.value || checking?.[key]?.notes)
      )
      .map(async ({ key }, index) => {
        const axiosResponse = await Axios.get(
          `${process.env.REACT_APP_SERVER_URL}/public/objectByUrl?url=${checking?.[key]?.value}`,
          { responseType: "arraybuffer" }
        );

        let blob = new Blob([axiosResponse.data], {
          type: axiosResponse.headers["content-type"],
        });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${idBooking}_${checkingKey}_${key}.jpg`;

        link.click();
      });
  };

  const handleStepChange = ({ min, max, step }) => {
    ((step > activeStep && step <= max) ||
      (step < activeStep && step >= min)) &&
      setActiveStep(step);
  };

  const DeliveryItem = () => {
    const { key, totalAmount } = delivery || {};

    return key === "agencyPickUp" ? (
      <Typography color={Colors.grey}>
        {Language.contractDetails.agencyPickUp}
      </Typography>
    ) : key === "toDeliver" && totalAmount ? (
      <Typography color={Colors.grey}>
        {`${Language.contractDetails.toDeliver} : ${totalAmount}€`}
      </Typography>
    ) : (
      <Typography color={Colors.grey}>
        {Language.contractDetails.noDeliverySelected}
      </Typography>
    );
  };

  const LokerItem = () => {
    const _member = members?.find(({ id }) => id === createdBy);
    const _date = Moment(createdAt).format("DD/MM/YYYY");

    return (
      <Typography color={Colors.grey}>
        {`${Language.contractDetails.createdBy} ${_member?.firstName} ${_member?.lastName} le ${_date}`}
      </Typography>
    );
  };
  const ServicesItem = ({ data }) => {
    const _data = data ? Object.keys(data).filter((el) => data[el]) : [];
    return _data?.length ? (
      _data?.map(
        (key) =>
          data[key] && (
            <Typography color={Colors.grey} key={key}>
              {`${keyLabelConverter[key]} : ${data[key]}€`}
            </Typography>
          )
      )
    ) : (
      <Typography color={Colors.grey}>
        {Language.contractDetails.noServiceSelected}
      </Typography>
    );
  };

  const TabPanel = ({ checking, index }) => {
    const { icon, onClick, title } = checkingsButtonList[tab] || {};
    const _checkingKeys = checking ? Object.keys(checking) : [];

    const urlCheckings = checkingsConfig?.items
      .filter(
        ({ key, type }) =>
          type === "picture" &&
          (checking?.[key]?.value || checking?.[key]?.notes)
      )
      .map(({ key }) => ({
        key,
        notes: checking?.[key]?.notes,
        value: checking?.[key]?.value,
      }));
    const textCheckings = checkingsConfig?.items
      .filter(
        ({ key, type }) =>
          ["slider", "numeric"].includes(type) && checking?.[key]?.value
      )
      .map(({ key, unity }) => ({ key, unity, value: checking?.[key]?.value }));
    const _loker = members?.find(({ id }) => id === checking?.createdBy);

    const _checkerInformations = `${
      Language.contractDetails.createdAtCheck
    } ${Moment(checking?.createdAt, "YYYY-MM-DD,HH:mm:ss").format(
      "DD/MM/YYYY"
    )} ${Language.contractDetails.by} ${_loker?.firstName} ${_loker?.lastName}`;

    return (
      <>
        {index === tab ? (
          _checkingKeys?.length ? (
            <Box>
              {urlCheckings?.length ? (
                <Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    padding="1vw"
                    alignItems="center"
                  >
                    <Button
                      color="secondary"
                      onClick={() =>
                        handleStepChange({
                          min: 0,
                          max: urlCheckings?.length - 1,
                          step: activeStep - 1,
                        })
                      }
                    >
                      <Typography fontSize={30} fontWeight="bold">
                        {Language.contractDetails.last}
                      </Typography>
                    </Button>
                    <Link
                      underline="none"
                      href={urlCheckings?.[activeStep]?.value}
                      target="_blank"
                    >
                      <Typography
                        backgroundColor={Colors.green}
                        borderRadius="5px"
                        padding={1}
                        color={Colors.white}
                        style={{ cursor: "pointer", textAlign: "center" }}
                      >
                        {keyLabelConverter[urlCheckings?.[activeStep]?.key]}
                      </Typography>
                    </Link>
                    <Button
                      color="secondary"
                      onClick={(step) =>
                        handleStepChange({
                          min: 0,
                          max: urlCheckings?.length - 1,
                          step: activeStep + 1,
                        })
                      }
                    >
                      <Typography fontSize={30} fontWeight="bold">
                        {Language.contractDetails.next}
                      </Typography>
                    </Button>
                  </Box>
                  <SwipeableViews
                    style={{ textAlign: "center" }}
                    axis={"x"}
                    index={activeStep}
                    onChangeIndex={(step) =>
                      handleStepChange({
                        min: 0,
                        max: urlCheckings?.length - 1,
                        step: step,
                      })
                    }
                    enableMouseEvents
                    id="swipeableViews"
                  >
                    {(urlCheckings || []).map(({ key, notes, value }, index) =>
                      displayImage({ url: value, notes, index, key })
                    )}
                  </SwipeableViews>
                </Box>
              ) : (
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: 100,
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      textAlign: "center",
                      fontStyle: "italic",
                      color: Colors.greyShaded,
                    }}
                  >
                    {Language.contractDetails.noImageAvailable}
                  </Typography>
                </Box>
              )}
              <Divider style={{ margin: "1vw" }} />
              <Box>
                {textCheckings.map(({ key, unity, value }) => {
                  return (
                    <Box key={key}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <>
                          <Typography
                            fontWeight="bold"
                            color={Colors.grey}
                          >{`${keyLabelConverter[key]}`}</Typography>
                          <Typography color={Colors.grey}>{`: ${value} ${
                            unity || ""
                          }`}</Typography>
                        </>
                      </Box>
                      {checking[key].notes && (
                        <Box>
                          <Typography
                            fontSize={12}
                            color={Colors.grey}
                            fontStyle="italic"
                          >{`${Language.contractDetails.note} ${checking[key].notes}`}</Typography>
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
              <Divider style={{ margin: "1vw" }} />
              <Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  width="100%"
                >
                  <Typography
                    style={{
                      textAlign: "right",
                      width: "100%",
                      fontStyle: "italic",
                    }}
                    color={Colors.grey}
                  >
                    {_checkerInformations}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              minHeight="80vh"
            >
              <Tooltip title={title}>
                <IconButton onClick={onClick}>{icon}</IconButton>
              </Tooltip>
              <Typography color={Colors.greyShaded}>{title}</Typography>
            </Box>
          )
        ) : null}
      </>
    );
  };

  return (
    <Dialog fullScreen open={true}>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <>
          {isOpenAddUpBook && (
            <AddUpdateBooking
              initialBooking={contractDetails}
              handleClose={() => setIsOpenAddUpBook(false)}
              handleMergeBooking={handleMergeBooking}
              members={members}
              role="updateBooking"
              setSnackBar={(props) => setSnackBar(props)}
            />
          )}
          <Box>
            <AppBar position="static">
              <Toolbar>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => setContractDetails(null)}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  {customer?.name}
                </Typography>
                {appBarButtonList.map((iconButton) => (
                  <Tooltip title={iconButton.tooltip} key={iconButton.key}>
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                      disabled={iconButton.restriction}
                      onClick={iconButton.onClick}
                    >
                      {iconButton.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              </Toolbar>
            </AppBar>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <Box
              width="50%"
              style={{
                borderWidth: 1,
                borderColor: Colors.greyHighShaded,
                borderRightStyle: "solid",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <Typography variant="h6" textAlign="center" color={Colors.grey}>
                  {Language.contractDetails.contractInformations}
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignitems: "center",
                    gap: 5,
                  }}
                >
                  <Typography textAlign="center" color={Colors.grey}>
                    {`${idBooking}`}
                  </Typography>
                  <Tooltip title={Language.contractDetails.copyTooltip}>
                    <ContentCopyIcon
                      size="large"
                      edge="start"
                      aria-label="menu"
                      onClick={() => navigator.clipboard.writeText(idBooking)}
                      style={{ cursor: "pointer", color: Colors.green }}
                    />
                  </Tooltip>
                </Box>
              </Box>
              {[
                {
                  title: `${Language.contractDetails.loker.toUpperCase()}`,
                  component: <LokerItem />,
                },
                {
                  title: customerConfiguration?.label.toUpperCase(),
                  component: customerConfiguration?.items?.map((item) => (
                    <Box flexDirection="row" key={item?.key}>
                      <Typography color={Colors.grey}>
                        {contractDetails?.customer[item?.key] &&
                          `${item.label} : ${
                            contractDetails?.customer[item?.key]
                          }`}
                      </Typography>
                    </Box>
                  )),
                },
                {
                  title: `${machineConfig?.label.toUpperCase()}`,
                  component: (
                    <Box
                      display="flex"
                      flex={1}
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box flex={3}>
                        {machineConfig?.items?.map((item) => (
                          <Box flexDirection="row" key={item.key}>
                            <Typography color={Colors.grey}>
                              {machine[item?.key] &&
                                `${item.label} : ${machine[item?.key]}`}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                      <Box flex={1} textAlign="center">
                        <img
                          src={
                            pictures?.frontLeft ||
                            pictures?.frontRight ||
                            pictures?.rearLeft ||
                            pictures?.rearRight ||
                            null
                          }
                          width="150px"
                          style={{ boxShadow: "2px 2px 5px grey" }}
                        />
                      </Box>
                    </Box>
                  ),
                },
                {
                  title: `${Language.contractDetails.rentalPeriod.toUpperCase()}`,
                  component: (
                    <>
                      <Typography color={Colors.grey}>
                        {`${rentalPrices.quantity} ${
                          Language.contractDetails[rentalPrices.key]
                        } x ${rentalPrices[rentalPrices.key]}€`}
                      </Typography>
                      <Box textAlign="center" padding="2%">
                        <LokaDatePicker
                          isEditable={false}
                          rentalPeriod={rentalPeriod}
                        />
                      </Box>
                    </>
                  ),
                },
                {
                  title: `${Language.contractDetails.equipments.toUpperCase()}`,
                  component: <EquipmentsItem data={equipmentsSelected} />,
                },
                {
                  title: `${Language.contractDetails.services.toUpperCase()}`,
                  component: <ServicesItem data={servicesSelected} />,
                },
                {
                  title: `${Language.contractDetails.delivery.toUpperCase()}`,
                  component: <DeliveryItem />,
                },
              ].map(({ title, component }, index) => (
                <Box
                  key={index}
                  id="customer"
                  style={{
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderColor: Colors.greyHighShaded,
                    borderRadius: "1vw",
                    margin: "1vw",
                    padding: "1vw",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color={Colors.green}
                    fontWeight="bold"
                  >
                    {title}
                  </Typography>
                  <Divider />
                  {component}
                </Box>
              ))}
            </Box>
            <Box width="50%">
              <Typography variant="h6" textAlign="center" color={Colors.grey}>
                {Language.contractDetails.checkingInformations}
              </Typography>
              <Box
                style={{
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: Colors.greyHighShaded,
                  borderRadius: "1vw",
                  margin: "1vw",
                  padding: "1vw",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Tabs
                    value={tab}
                    onChange={(event, value) => setTab(value)}
                    aria-label="checking-tab"
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    <Tab label={Language.contractDetails.departure} />
                    <Tab
                      label={Language.contractDetails.return}
                      disabled={!contractsToStopCheckings}
                    />
                  </Tabs>
                  {contractsToStartCheckings ? (
                    <div>
                      <Button
                        onClick={() =>
                          handleGetCheckingImages({
                            checking:
                              tab === 0
                                ? contractsToStartCheckings
                                : contractsToStopCheckings,
                            checkingKey:
                              tab === 0 ? "startChecking" : "stopChecking",
                          })
                        }
                      >
                        <Tooltip
                          title={Language.contractDetails.downloadImages}
                        >
                          <ImageIcon style={{ color: Colors.green }} />
                        </Tooltip>
                      </Button>
                      <Button
                        onClick={() =>
                          handleGetPdfBooking({
                            docType:
                              tab === 0 ? "startChecking" : "stopChecking",
                          })
                        }
                      >
                        <Tooltip title={Language.contractDetails.pdfBooking}>
                          <PictureAsPdfIcon style={{ color: Colors.green }} />
                        </Tooltip>
                      </Button>
                    </div>
                  ) : null}
                </div>
                <Divider />
                <Box>
                  <TabPanel
                    index={0}
                    checking={contractsToStartCheckings || {}}
                  />
                  <TabPanel
                    index={1}
                    checking={contractsToStopCheckings || {}}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <LokaSnackBar />
        </>
      )}
    </Dialog>
  );
};
export default ContractDetails;
